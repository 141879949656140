import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMountedState, useUnmountPromise } from 'react-use';

import { useGetEnvironmentVariables } from '../useGetEnvironmentVariables';
import { AuthContext } from './context';
import {
  CompleteNewPasswordInput,
  ConfirmSignUpInput,
  DriveWealthUser,
  ForgotPasswordInput,
  ResendSignUpInput,
  ResetPasswordInput,
  SignInInput,
} from './types';

export type UseAuthReturnValue = {
  user: CognitoUser | null;
  challenge: unknown | null;
  signIn: (input: SignInInput) => ReturnType<(typeof Auth)['signIn']>;
  signOut: () => ReturnType<(typeof Auth)['signOut']>;
  deleteUser: () => ReturnType<(typeof Auth)['deleteUser']>;
  confirmSignIn: (code: string) => ReturnType<(typeof Auth)['confirmSignIn']>;
  unsetUser: () => void;
};

export const useAuth = (): UseAuthReturnValue => {
  const [challenge, setChallenge] = useState<string | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const mounted = useUnmountPromise();
  const isMounted = useMountedState();
  const { isSuccess } = useGetEnvironmentVariables();

  useEffect(() => {
    const check = async () => {
      try {
        // Bail out if bootstrap vars haven't been fetched
        if (!isSuccess) return;

        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        setUser(null);
      }
    };

    check();
  }, [isSuccess]);

  const signIn = useCallback(
    async ({ username, password }: SignInInput) => {
      // ERROR: mounted here causes infinite loading state
      const signedInUser = await Auth.signIn(username, password);

      if (signedInUser?.challengeName) {
        setChallenge(signedInUser);
      } else {
        setUser(signedInUser);
        setChallenge(null);
      }
    },
    [mounted],
  );

  const signOut = useCallback(async () => {
    console.log('Signing out - hook:66');
    await mounted(Auth.signOut());

    setUser(null);
    setChallenge(null);
  }, [mounted]);

  // Intended for usage with useLogout to unset the user values to keep the session state in sync
  const unsetUser = useCallback(() => {
    setUser(null);
    setChallenge(null);
  }, []);

  const deleteUser = useCallback(async () => {
    user?.deleteUser((error?: Error) => {
      if (error) throw error;

      if (isMounted()) {
        setUser(null);
        setChallenge(null);
      }
    });
  }, [isMounted, user]);

  const confirmSignIn = useCallback(
    async (code: string) => {
      await mounted(Auth.confirmSignIn(challenge, code));
      const currentUser = await mounted(Auth.currentAuthenticatedUser());
      setUser(currentUser);
      setChallenge(null);
    },
    [challenge, mounted],
  );

  return { user, challenge, confirmSignIn, signIn, signOut, deleteUser, unsetUser };
};

export const useUser = (): DriveWealthUser | null => {
  const { user } = useContext(AuthContext);
  if (!user) return null;

  // See https://github.com/aws-amplify/amplify-js/issues/4927
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return user.attributes;
};

export const useCurrentSession = (): (() => Promise<CognitoUserSession>) => {
  return Auth.currentSession;
};

export const useSignIn = (): ((input: SignInInput) => Promise<void>) => {
  return useContext(AuthContext).signIn;
};

export const useConfirmSignIn = (): ((code: string) => Promise<void>) => {
  return useContext(AuthContext).confirmSignIn;
};

export const useSignOut = (): (() => Promise<void>) => {
  return useContext(AuthContext).signOut;
};

export const useUnsetUser = (): (() => void) => {
  return useContext(AuthContext).unsetUser;
}

export const useConfirmSignUp = (): ((input: ConfirmSignUpInput) => Promise<void>) => {
  return async function confirmSignUp({ username, code }: ConfirmSignUpInput) {
    await Auth.confirmSignUp(username, code);
  };
};

export const useResendSignUp = (): ((input: ResendSignUpInput) => Promise<void>) => {
  return async function resendSignUp({ username }: ResendSignUpInput) {
    await Auth.resendSignUp(username);
  };
};

export const useForgotPassword = (): ((input: ForgotPasswordInput) => Promise<void>) => {
  return async function forgotPassword({ username }: ForgotPasswordInput) {
    await Auth.forgotPassword(username);
  };
};

export const useResetPassword = (): ((input: ResetPasswordInput) => Promise<void>) => {
  return async function resetPassword({ username, code, password }: ResetPasswordInput) {
    await Auth.forgotPasswordSubmit(username, code, password);
  };
};

export const useCompleteNewPassword = (): ((input: CompleteNewPasswordInput) => Promise<void>) => {
  return async function completeNewPassword({
    user,
    password,
    phoneNumber,
  }: CompleteNewPasswordInput) {
    const cleanedPhoneNumber = phoneNumber.replace(/[^+\d]+/g, '');
    await Auth.completeNewPassword(user, password, { phone_number: cleanedPhoneNumber });
  };
};
